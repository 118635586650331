import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 33 33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M27.5 5.5h-22a2.73 2.73 0 0 0-2.736 2.75l-.014 16.5A2.74 2.74 0 0 0 5.5 27.5h22a2.74 2.74 0 0 0 2.75-2.75V8.25A2.74 2.74 0 0 0 27.5 5.5m0 19.25h-22V16.5h22zm0-13.75h-22V8.25h22z"
    }, null, -1)
  ])))
}
export default { render: render }